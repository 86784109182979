import { ArrowLeft, ArrowRight, Circle } from '@phosphor-icons/react'
import clsx from 'clsx'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { useContext, useEffect, useMemo } from 'react'

import { STATUS_COLORS } from '@/config/module/colorConstant'
import HNContext from '@/context/HNContext'
import { changelogLabelFilter } from '@/lib/helpers/modules/changelogHelper'
import type { IChangelogLabel } from '@/types/organization'

interface IChangelogLabelWithActive extends IChangelogLabel {
  isActive?: boolean
}

const UserChangelogLabelsItem = ({
  label,
  labels,
  className,
}: {
  label: IChangelogLabelWithActive
  labels: string[]
  className?: string
}) => {
  const router = useRouter()
  return (
    <li
      className={clsx(
        'mx-1 rounded-full border px-2 py-0.5 text-sm font-medium hover:border-gray6 hover:bg-gray3 md:px-2',
        label.isActive && 'border-gray8 bg-gray4',
        !label.isActive && 'border-gray4',
        className
      )}
      data-testid={`user_changelog_labels_filter_${label.id}`}
      key={label.id}
    >
      <Link
        href={{
          pathname: router.pathname,
          query: {
            ...router.query,
            labels: changelogLabelFilter(labels, label, !!label.isActive),
          },
        }}
        shallow={true}
        className='flex items-center gap-2'
      >
        <Circle
          weight='fill'
          className='h-3 w-3'
          color={STATUS_COLORS?.find((c) => c.value === label.color)?.hex}
        />
        <span className='whitespace-nowrap'>{label.name}</span>
      </Link>
    </li>
  )
}

export default function UserChangelogLabelsFilter() {
  const router = useRouter()
  const { labels } = router.query as { labels: string[] }
  const { changelog_labels } = useContext(HNContext)

  const scrollAreaRef = React.useRef<HTMLUListElement>(null)
  const scrollStartAreaRef = React.useRef<HTMLDivElement>(null)
  const scrollEndAreaRef = React.useRef<HTMLDivElement>(null)
  const [showStartFadeArea, setShowStartFadeArea] = React.useState(false)
  const [showEndFadeArea, setShowEndFadeArea] = React.useState(false)

  const filteredLabels = useMemo(() => {
    if (router?.query?.labels) {
      return changelog_labels?.filter((_label) =>
        router.query.labels?.includes(_label.id.toString())
      )
    }
    return []
  }, [router.query.labels])

  const scrollLeft = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollLeft -= 200
    }
  }

  const scrollRight = () => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollLeft += 200
    }
  }

  useEffect(() => {
    if (scrollStartAreaRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setShowStartFadeArea(!entry.isIntersecting)
          })
        },
        { threshold: 1 }
      )

      observer.observe(scrollStartAreaRef.current)
    }

    if (scrollEndAreaRef.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setShowEndFadeArea(!entry.isIntersecting)
          })
        },
        { threshold: 0.2 }
      )

      observer.observe(scrollEndAreaRef.current)
    }
  }, [])

  const changelogLabels: IChangelogLabelWithActive[] = useMemo(
    () =>
      changelog_labels
        ?.sort((a, b) => (a.position || 0) - (b.position || 0))
        ?.map((label) => ({
          ...label,
          isActive: filteredLabels?.map((l) => l.id).includes(label.id),
        }))
        .sort((a, b) => {
          if (a.isActive && !b.isActive) return -1
          if (!a.isActive && b.isActive) return 1
          return 0
        }) || [],
    [filteredLabels]
  )

  return (
    <div className='relative overflow-hidden'>
      {showStartFadeArea && (
        <div
          className='absolute bottom-0 left-0 top-0 z-[2] flex w-12 cursor-pointer items-center justify-start bg-gradient-to-r from-snow via-snow dark:from-gray2 dark:via-gray2'
          onClick={scrollLeft}
        >
          <ArrowLeft className='text-gray10' />
        </div>
      )}
      {showEndFadeArea && (
        <div
          className='absolute bottom-0 right-0 top-0 z-[1] flex w-12 cursor-pointer items-center justify-end bg-gradient-to-l from-snow via-snow dark:from-gray2 dark:via-gray2'
          onClick={scrollRight}
        >
          <ArrowRight className='text-gray10' />
        </div>
      )}
      <ul
        data-testid='user_side_changelog_filter'
        className='relative flex flex-1 flex-nowrap overflow-x-scroll scroll-smooth py-1 pr-1 scrollbar-hide'
        ref={scrollAreaRef}
      >
        <div ref={scrollStartAreaRef} className='w-1' />
        {changelogLabels.map((label, i) => {
          return (
            <UserChangelogLabelsItem
              key={label.id}
              label={label}
              labels={labels}
              className={i === 0 ? '!ml-0' : ''}
            />
          )
        })}
        <div ref={scrollEndAreaRef} className='w-1' />
      </ul>
    </div>
  )
}
