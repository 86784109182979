import clsx from 'clsx'
import type { SearchResponse } from 'meilisearch'
import { useRouter } from 'next/router'
import { NextSeo } from 'next-seo'
import React, { useContext, useEffect, useRef, useState } from 'react'

import ChangelogLoader from '@/components/changelog/ChangelogLoader'
import UserChangelogHeader from '@/components/changelog/UserChangelogHeader'
import UserChangelogItem from '@/components/changelog/UserChangelogItem'
import PageTitle from '@/components/shared/common/PageTitle'
import InfiniteScroll from '@/components/shared/components/InfiniteLoaders'
import EmptyState from '@/components/shared/ui/EmptyState'
import HNContext from '@/context/HNContext'
import { useSearchClient } from '@/context/HNSearchContext'
import { useTranslations } from '@/hooks/useTranslations'
import { cleanChangelogSearchResults } from '@/lib/helpers/modules/changelogHelper'
import { userChangelogPath } from '@/lib/helpers/pathHelpers'
import { getChangelogs } from '@/models/Changelog'
import type { IChangelog } from '@/types/changelog'

interface IPropTypes {
  initialChangelogs?: IChangelog[]
}

export default function UserChangelogList({ initialChangelogs }: IPropTypes) {
  const t = useTranslations()
  const [changelogs, setChangelogs] = useState<IChangelog[]>(
    initialChangelogs || []
  )
  const [searchResponse, setSearchResponse] =
    useState<SearchResponse<IChangelog> | null>(null)
  const router = useRouter()
  const { organizationSetting, organization } = useContext(HNContext)
  const [canFetchMore, setCanFetchMore] = useState(false)
  const [canFetchMoreSearch, setCanFetchMoreSearch] = useState(false)
  const [loading, setLoading] = useState(!initialChangelogs?.length)
  const [errorMessage, setErrorMessage] = useState<string | null>(null)
  const page = useRef<number>(initialChangelogs?.length ? 2 : 1)
  const searchPage = useRef<number>(1)
  const initialLoadComplete = useRef<boolean>(false)
  const { searchClient } = useSearchClient()
  const searchText = useRef<string>('')
  const searchDebounceTimer = useRef<NodeJS.Timeout | null>(null)

  const fetchChangelogs = (loadMore: boolean = false) => {
    if (!loadMore && initialLoadComplete.current) setLoading(true)
    getChangelogs({ ...router.query, page: page.current })
      .then((newChangelogs) => {
        setCanFetchMore(newChangelogs.length >= 10)
        setChangelogs((oldChangelogs) =>
          page.current === 1
            ? newChangelogs
            : [...oldChangelogs, ...newChangelogs]
        )
      })
      .catch((err) => setErrorMessage(err.message))
      .finally(() => setLoading(false))
  }

  const handleSearch = (search: string) => {
    searchText.current = search
    if (!searchClient) return Promise.resolve()
    if (!search) {
      setSearchResponse(null)
      return Promise.resolve()
    }

    if (searchDebounceTimer.current) {
      clearTimeout(searchDebounceTimer.current)
    }
    searchDebounceTimer.current = setTimeout(() => {
      return searchClient
        .index('Changelog')
        .search<IChangelog>(search, {
          hitsPerPage: 10,
          filter: ['published = true'],
          sort: ['numeric_published_at:asc'],
          attributesToHighlight: ['title'],
          attributesToSearchOn: ['title'],
          page: searchPage.current,
          limit: 10,
        })
        .then((response) => {
          const sortedHits = response.hits
            .sort(
              (a, b) =>
                (b.numeric_published_at || 0) - (a.numeric_published_at || 0)
            )
            .map((hit) => cleanChangelogSearchResults(hit))
          setSearchResponse({
            ...response,
            hits:
              searchPage.current === 1
                ? sortedHits
                : [...(searchResponse?.hits || []), ...sortedHits],
          })
          setCanFetchMoreSearch(response.hits.length >= 10)
        })
    }, 200)
    return () => {
      if (searchDebounceTimer.current) {
        clearTimeout(searchDebounceTimer.current)
      }
    }
  }

  const handlePageUpdate = (incrementor: number): void => {
    page.current += incrementor
    fetchChangelogs(true)
  }

  const handleSearchPageUpdate = (incrementor: number): void => {
    searchPage.current += incrementor
    handleSearch(searchText.current)
  }

  useEffect(() => {
    if (initialLoadComplete.current || !initialChangelogs?.length) {
      page.current = 1
      fetchChangelogs()
    }
    if (!initialLoadComplete.current) {
      initialLoadComplete.current = true
      if (initialChangelogs && initialChangelogs?.length >= 10) {
        setCanFetchMore(true)
      }
    }
  }, [router.query])

  const renderChangelog = (changelog: IChangelog) => (
    <UserChangelogItem key={changelog.id} changelog={changelog} />
  )

  const renderChangelogItems = () => {
    if (loading)
      return (
        <>
          <ChangelogLoader data-testid='user_side_changelog_spinner' />
          <ChangelogLoader data-testid='user_side_changelog_spinner' />
        </>
      )
    if (errorMessage)
      return (
        <EmptyState
          dataTestId='user_side_changelog_error_empty_state'
          description={errorMessage}
        />
      )
    if (searchResponse) {
      if (searchResponse.hits.length) {
        return <>{searchResponse.hits?.map(renderChangelog)}</>
      }
      return (
        <EmptyState
          dataTestId='user_side_changelog_empty_state'
          description='No changelog'
        />
      )
    }
    if (!changelogs.length)
      return (
        <EmptyState
          dataTestId='user_side_changelog_empty_state'
          description='No changelog'
        />
      )

    return <>{changelogs?.map(renderChangelog)}</>
  }

  return (
    <>
      {/* Add connanical using next-seo when labels are present in url query */}
      {router.query?.labels && organization && organization.home_page && (
        <NextSeo canonical={userChangelogPath(organization?.home_page)} />
      )}
      <PageTitle
        title={[organizationSetting?.changelog_plural_name].toString()}
      />
      <section data-testid='user_side_changelog_list' className='py-2 md:py-5'>
        <div className='mx-auto flex max-w-7xl flex-col gap-5'>
          <UserChangelogHeader
            onSearch={(search) => {
              searchPage.current = 1
              handleSearch(search)
            }}
          />
        </div>
        <div className='relative mx-auto flex max-w-7xl flex-col gap-5'>
          <div className={clsx('flex flex-col gap-5 px-2 md:gap-10 md:pl-8')}>
            {searchResponse && (
              <div className='flex items-center justify-between gap-2 py-2'>
                <p
                  className='text-base font-medium text-gray11'
                  dangerouslySetInnerHTML={{
                    __html: t('messages.searchResults', {
                      data: { query: searchResponse.query },
                    }),
                  }}
                />
                <p
                  className='text-base font-medium text-gray11'
                  dangerouslySetInnerHTML={{
                    __html: t('messages.hitsCount', {
                      data: {
                        count: Number(searchResponse?.hits?.length || 0),
                      },
                    }),
                  }}
                />
              </div>
            )}
            {renderChangelogItems()}
            {searchResponse ? (
              <InfiniteScroll
                count={searchResponse.hits.length}
                showIf={Boolean(searchResponse.hits.length)}
                canFetchMore={canFetchMoreSearch}
                onIntersect={() => handleSearchPageUpdate(1)}
              />
            ) : (
              <InfiniteScroll
                count={changelogs.length}
                showIf={Boolean(changelogs.length)}
                canFetchMore={canFetchMore}
                onIntersect={() => handlePageUpdate(1)}
              />
            )}
          </div>
        </div>
      </section>
    </>
  )
}
