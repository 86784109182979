import {
  Clipboard,
  EnvelopeSimple,
  IconContext,
  TwitterLogo,
} from '@phosphor-icons/react'
import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { copyClipboard } from '@/lib/helpers/appHelpers'
import {
  getChangelogShareURL,
  getChangelogURL,
} from '@/lib/helpers/modules/changelogHelper'
import type { IChangelog } from '@/types/changelog'

import PrimaryLink from '../shared/ui/Links/PrimaryLink'
import Tooltip from '../shared/ui/Tooltip'

interface IPropTypes {
  dataTestId?: string
  changelog: IChangelog
}

export default function ChangelogLinks({
  changelog,
  dataTestId = 'changelog_links',
}: IPropTypes) {
  const { organization, embedType } = useContext(HNContext)
  const t = useTranslations()

  if (!changelog) return <></>
  return (
    <div
      data-testid={dataTestId}
      className='flex items-center justify-center space-x-2 sm:justify-end'
    >
      <IconContext.Provider value={{ size: 12, weight: 'fill' }}>
        <Tooltip text={t('buttons.clickToCopy', { ignorePrefix: true })}>
          <Clipboard
            size={24}
            data-testid='changelog_link_share'
            className='rounded-full bg-gray5 p-1.5 hover:text-primary'
            onClick={() =>
              copyClipboard(
                getChangelogURL(changelog, organization?.home_page),
                t('buttons.copied')
              )
            }
          />
        </Tooltip>
        <Tooltip text={t('changelog.labels.shareOnTwitter')}>
          <PrimaryLink
            data-testid='changelog_twitter_share'
            target={'_blank'}
            href={getChangelogShareURL(
              changelog,
              'twitter',
              organization?.home_page as string
            )}
            className='rounded-full bg-gray5 p-1.5 hover:text-primary'
          >
            <TwitterLogo />
          </PrimaryLink>
        </Tooltip>
        <Tooltip text={t('changelog.labels.sendAsMail')}>
          <PrimaryLink
            data-testid='changelog_mail_share'
            href={getChangelogShareURL(
              changelog,
              'mail',
              organization?.home_page as string
            )}
            className='rounded-full bg-gray5 p-1.5 hover:text-primary'
            target={embedType === 'changelogWidget' ? '_blank' : undefined}
          >
            <EnvelopeSimple />
          </PrimaryLink>
        </Tooltip>
      </IconContext.Provider>
    </div>
  )
}
