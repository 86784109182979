import { Bell, BellSlash } from '@phosphor-icons/react'
import React, { useContext, useMemo, useState } from 'react'

import Button from '@/components/shared/ui/Button'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import {
  subscribeToChangelog,
  unsubscribeToChangelog,
} from '@/models/Changelog'
import toaster from '@/utils/toast'

export default function UserChangelogSubscribeButton() {
  const t = useTranslations('changelog')
  const { userProfile, bootstraped, updateContext } = useContext(HNContext)
  const [subscribing, setSubscribing] = useState(false)

  const subscribed = useMemo(
    () => !!userProfile?.subscribed_for_changelog_published,
    [userProfile?.subscribed_for_changelog_published]
  )

  const handleSubscribe = () => {
    setSubscribing(true)
    const newState = !subscribed
    const promise = newState ? subscribeToChangelog() : unsubscribeToChangelog()

    return promise
      .then(() =>
        updateContext?.({
          userProfile: {
            ...userProfile,
            subscribed_for_changelog_published: newState,
          },
        })
      )
      .then(() => {
        toaster.success({
          message: t(
            `subscription.${
              subscribed ? 'unsubscribedSuccess' : 'subscribedSuccess'
            }`
          ),
        })
      })
      .catch(toaster.error)
      .finally(() => setSubscribing(false))
  }

  const renderSubscribeText = () => {
    if (subscribed) {
      if (subscribing) return 'Unsubscribing...'
      return 'Unsubscribe'
    }
    if (subscribing) return 'Subscribing...'
    return 'Subscribe'
  }

  const renderSubscribeIcon = () => {
    if (subscribed) {
      return <BellSlash weight='fill' />
    }
    return <Bell weight='fill' />
  }

  if (!userProfile) return null
  if (!bootstraped) return null
  return (
    <Button
      icon={renderSubscribeIcon()}
      variant={subscribed ? 'outline' : 'primary'}
      onClick={() => {
        handleSubscribe()
      }}
      size='sm'
    >
      {renderSubscribeText()}
    </Button>
  )
}
