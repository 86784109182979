import { RssSimple } from '@phosphor-icons/react'
import React, { useContext } from 'react'

import UserChangelogLabelsFilter from '@/components/changelog/UserChangelogLabelsFilter'
import UserChangelogSubscribeButton from '@/components/changelog/UserChangelogSubscribeButton'
import Button from '@/components/shared/ui/Button'
import Input from '@/components/shared/ui/Input'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'

interface IUserChangelogHeaderProps {
  onSearch: (search: string) => void
}
export default function UserChangelogHeader({
  onSearch,
}: IUserChangelogHeaderProps) {
  const t = useTranslations('changelog')
  const { organizationSetting } = useContext(HNContext)

  const renderRss = () => {
    if (organizationSetting?.organization_privacy) return <></>

    return (
      <>
        <p className='font-light opacity-40'>|</p>
        <Button
          className='flex items-center gap-1 p-1 hover:text-primary'
          href='/rss/changelog.xml'
          target='_blank'
          size='xs'
          variant='outline'
          icon={<RssSimple />}
        >
          {' '}
          RSS
        </Button>
      </>
    )
  }

  return (
    <div className='flex flex-col px-4 pt-4 md:px-6 md:pt-6'>
      <div className='flex flex-col gap-2 sm:flex-row sm:items-center sm:justify-between'>
        <div className='flex items-center gap-2'>
          <h1 className='text-3xl font-semibold'>
            {organizationSetting?.changelog_plural_name}
          </h1>
          {renderRss()}
        </div>
        <div className='flex gap-2 md:self-end'>
          <Input
            placeholder='Search'
            className='w-full'
            size='sm'
            onChange={(e) => {
              onSearch(e.target.value)
            }}
          />
          <UserChangelogSubscribeButton />
        </div>
      </div>
      <div className='flex flex-col justify-between gap-4 md:flex-row md:items-center'>
        <div className='flex items-center justify-start gap-2 text-base text-gray11 '>
          <div
            className='prose-xs w-full prose-a:font-normal prose-a:text-gray11 hover:prose-a:text-primary hover:prose-a:no-underline'
            dangerouslySetInnerHTML={{
              __html:
                organizationSetting?.changelog_description ||
                t('header.subtitle'),
            }}
          />
        </div>
      </div>
      <div className='py-2'>
        <UserChangelogLabelsFilter />
      </div>
      <div className='mt-4 h-px bg-gray7 dark:bg-gray8' />
    </div>
  )
}
