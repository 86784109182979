/* eslint-disable no-underscore-dangle */
import { ArrowDown } from '@phosphor-icons/react'
import clsx from 'clsx'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { useContext, useEffect, useRef, useState } from 'react'

import HTMLViewer from '@/components/shared/components/HTMLViewer'
import NavLink from '@/components/shared/ui/Links/NavLink'
import Tooltip from '@/components/shared/ui/Tooltip'
import HNContext from '@/context/HNContext'
import { useTranslations } from '@/hooks/useTranslations'
import { formatJSONTimestamp, relativeDate } from '@/lib/helpers/dateHelpers'
import {
  userSideChangelogSinglePath,
  userSideEmbedChangelogSinglePath,
} from '@/lib/helpers/pathHelpers'
import { cleanHTMLString } from '@/lib/helpers/stringHelpers'
import type { IChangelog } from '@/types/changelog'

import { getBasePathPrefix } from '../../lib/helpers/urlHelpers'
import Badge from '../shared/ui/Badge'
import UnstyledLink from '../shared/ui/Links/UnstyledLink'
import Typography from '../shared/ui/Typography'
import ChangelogLinks from './ChangelogLinks'
import ChangelogReactions from './ChangelogReactions'
import UserChangelogLabels from './UserChangelogLabels'

interface IPropTypes {
  changelog: IChangelog
}
const CHANGELOG_MAX_HEIGHT = 500

export default function UserChangelogItem({ changelog }: IPropTypes) {
  const t = useTranslations()
  const router = useRouter()
  const { embedType } = useContext(HNContext)

  const [isMinimized, setIsMinimized] = useState<boolean>(false)
  const changelogRef = useRef<HTMLDivElement>(null)
  const initialLoad = useRef<boolean>(false)

  const getHref = () => {
    if (embedType === 'moduleEmbed') {
      return userSideEmbedChangelogSinglePath(
        changelog.slug,
        getBasePathPrefix(embedType)
      )
    }
    return userSideChangelogSinglePath(
      changelog.slug,
      getBasePathPrefix(embedType)
    )
  }

  const renderReadMoreButton = () => {
    return (
      <>
        {isMinimized && (
          <div className='flex items-center justify-center'>
            <Badge
              testId={`${changelog.id}_changelog_read_more_button`}
              size='sm'
              variant='gray'
              rounded
              icon={<ArrowDown />}
              onClick={() => setIsMinimized(false)}
              value={t('buttons.read_more')}
            />
          </div>
        )}
      </>
    )
  }

  useEffect(() => {
    if (
      !initialLoad.current &&
      changelogRef.current &&
      changelogRef.current.offsetHeight > CHANGELOG_MAX_HEIGHT
    ) {
      setIsMinimized(true)
      initialLoad.current = true
    }
  }, [changelogRef.current])

  return (
    <div
      data-testid={`user_side_changelog_${changelog.id}`}
      className='border-b border-gray4'
    >
      <div className='grid grid-cols-1 md:grid-cols-12'>
        <div className='col-span-2 px-2 py-1 md:px-0 md:py-5'>
          <Tooltip text={formatJSONTimestamp(changelog.published_at.timestamp)}>
            <NavLink
              href={userSideChangelogSinglePath(
                changelog.slug,
                getBasePathPrefix(embedType)
              )}
              className='my-0 flex text-left font-medium'
            >
              {relativeDate(changelog.published_at.timestamp)}
            </NavLink>
          </Tooltip>
        </div>
        <div className='col-span-10 space-y-2 p-2 md:p-5'>
          <UserChangelogLabels labels={changelog?.labels} />
          <Typography.Title
            className='pb-5 !text-xl font-semibold hover:text-primary'
            level={2}
          >
            <UnstyledLink
              data-testid={`user_side_changelog_${changelog.id}_title`}
              params={{
                widgetKey: router.query?.widgetKey as string,
                JWTToken: router.query?.JWTToken as string,
              }}
              href={getHref()}
            >
              <span
                className='highlight-search-text'
                dangerouslySetInnerHTML={{
                  __html: cleanHTMLString(
                    changelog._formatted?.title || changelog.title || ''
                  ),
                }}
              />
            </UnstyledLink>
          </Typography.Title>
          {changelog.feature_image_url && (
            <UnstyledLink
              params={{
                widgetKey: router.query?.widgetKey as string,
                JWTToken: router.query?.JWTToken as string,
              }}
              href={getHref()}
            >
              <Image
                src={changelog.feature_image_url}
                alt={changelog.title}
                width={1000}
                height={718}
                className='rounded'
              />
            </UnstyledLink>
          )}
          <div className='flex flex-col justify-between space-y-4 pb-3'>
            <div
              className='relative overflow-hidden'
              ref={changelogRef}
              data-testid={`${changelog.id}_description_html`}
            >
              <HTMLViewer
                className={clsx(
                  isMinimized
                    ? 'gradient-mask-b max-h-[20rem] overflow-hidden break-normal'
                    : ''
                )}
                html={changelog.description_html}
              />
              {renderReadMoreButton()}
            </div>
            <div className='mt-5 items-center justify-center space-y-4 sm:flex sm:justify-between sm:space-y-0'>
              <ChangelogReactions changelog={changelog} />
              <ChangelogLinks changelog={changelog} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
