import { useRouter } from 'next/router'
import React, { useContext } from 'react'

import HNContext from '@/context/HNContext'
import { changelogLabelFilter } from '@/lib/helpers/modules/changelogHelper'
import { userSideChangelogHomePath } from '@/lib/helpers/pathHelpers'
import type { IChangelogLabel } from '@/types/changelog'
import type { IKeyValueMap } from '@/types/common'

import Badge from '../shared/ui/Badge'
import type { IVariantTypes } from '../shared/ui/types/element'

interface IPropTypes {
  label: IChangelogLabel
  close: boolean
}
export default function UserChangelogLabelItem(props: IPropTypes) {
  const { embedType } = useContext(HNContext)
  const { label, close } = props
  const router = useRouter()
  const { labels } = router.query

  const handleFilter = () => {
    const queryParams: IKeyValueMap = {
      labels: changelogLabelFilter(labels, label, close),
    }

    router.push(
      {
        pathname: userSideChangelogHomePath(embedType),
        query: queryParams,
      },
      undefined,
      { shallow: true }
    )
  }

  return (
    <Badge
      testId={`user_changelog_label_item_${label.id}`}
      data={label}
      value={label.name}
      variant={label.color as IVariantTypes}
      onClick={handleFilter}
      close={close}
      onClose={handleFilter}
    />
  )
}
