import React from 'react'

import Skeleton from '@/components/shared/ui/Skeleton/Skeleton'

interface IPropTypes extends React.HTMLAttributes<HTMLDivElement> {}
export default function ChangelogLoader(props: IPropTypes) {
  return (
    <div className='min-h-50 w-full animate-pulse bg-gray3 p-5 ' {...props}>
      <Skeleton className='h-[30px] w-full rounded-lg' />

      <Skeleton className='mt-5 h-[200px] w-full rounded-lg' />
    </div>
  )
}
