import React from 'react'

import type { IChangelogLabel } from '@/types/changelog'

import UserChangelogLabelItem from './UserChangelogLabelItem'

interface IPropTypes {
  labels: IChangelogLabel[]
  close?: boolean
}
export default function UserChangelogLabels({
  labels,
  close = false,
}: IPropTypes) {
  const renderLabel = (label: IChangelogLabel) => (
    <UserChangelogLabelItem label={label} key={label.id} close={close} />
  )

  if (!labels?.length) return null
  return (
    <div
      data-testid='user_changelog_single_labels'
      className='inline-flex flex-wrap gap-1 overflow-hidden overflow-x-auto scrollbar-hide'
    >
      {labels.map(renderLabel)}
    </div>
  )
}
