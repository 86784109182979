import React from 'react'

import type { IChangelogReaction } from '@/types/changelog'

import Badge from '../shared/ui/Badge'
import Tooltip from '../shared/ui/Tooltip'

interface IPropTypes {
  reaction: IChangelogReaction
  onReaction: (reaction: IChangelogReaction) => Promise<void>
}

export default function UserChangelogReactionItem(props: IPropTypes) {
  const { reaction, onReaction } = props

  const handleReaction = () => {
    onReaction(reaction)
  }

  return (
    <Tooltip
      show={reaction.show_tooltip && Boolean(reaction.count > 0)}
      text={reaction.reacted_users}
    >
      <Badge
        variant={reaction.reacted ? 'gray' : 'outline'}
        rounded
        icon={reaction.emoji}
        value={reaction.count ? `${reaction.count}` : undefined}
        onClick={() => handleReaction()}
        testId={`reaction-${reaction.id}`}
      />
    </Tooltip>
  )
}
