import React, { useState } from 'react'

import { useTranslations } from '@/hooks/useTranslations'
import { orderArrayOfObjects } from '@/lib/helpers/dataHelpers'
import {
  addChangelogReaction,
  removeChangelogReaction,
} from '@/models/Changelog'
import type {
  IChangelog,
  IChangelogReactAPIParams,
  IChangelogReaction,
} from '@/types/changelog'
import toaster from '@/utils/toast'

import ChangelogReactionItem from './ChangelogReactionItem'

interface IPropTypes {
  dataTestId?: string
  changelog: IChangelog
}

export default function ChangelogReactions({
  changelog,
  dataTestId = 'changelog_reactions',
}: IPropTypes) {
  const t = useTranslations('changelog')
  const [reactionsList, setReactionsList] = useState(changelog.reactions)

  const setReactedUsersText = (reactionData: IChangelogReaction) => {
    if (!reactionData.reacted_users)
      return t('youReactedWithText', { data: { emoji: reactionData.emoji } })

    if (reactionData.reacted) {
      return reactionData.reacted_users.replace('You and', '')
    }

    return `${t('youAndText')} ${reactionData.reacted_users}`
  }

  const updateReactions = (reaction: IChangelogReaction) => {
    const data: IChangelogReactAPIParams = {
      resource_id: changelog.id,
      resource_type: 'Changelog',
      reaction_id: reaction.id,
    }

    const oldReactionList = [...reactionsList]
    try {
      const currentCount = reaction.count || 0
      const newOptimisticReaction = {
        id: reaction.id,
        emoji: reaction.emoji,
        count: reaction.reacted ? currentCount - 1 : currentCount + 1,
        reacted_users: setReactedUsersText(reaction),
        show_tooltip: true,
        reacted: !reaction.reacted,
      }
      setReactionsList((prev) =>
        prev.map((r) => (r.id === reaction.id ? newOptimisticReaction : r))
      )
    } catch (err: any) {
      toaster.error({ message: err.message })
    }

    const reactionFunc = reaction.reacted
      ? removeChangelogReaction(reaction.id, data)
      : addChangelogReaction(data)

    return reactionFunc.catch(() => {
      setReactionsList(oldReactionList)
    })
  }

  const renderReaction = (reaction: IChangelogReaction) => (
    <ChangelogReactionItem
      key={changelog.id + reaction.id}
      reaction={reaction}
      onReaction={updateReactions}
    />
  )

  const idSort = (a: IChangelogReaction, b: IChangelogReaction) => {
    if (a.id < b.id) return -1
    return 1
  }

  return (
    <div
      data-testid={dataTestId}
      className='flex flex-wrap items-center justify-center gap-1 text-center sm:justify-start'
    >
      {orderArrayOfObjects(reactionsList?.sort(idSort), 'count')?.map(
        renderReaction
      )}
    </div>
  )
}
